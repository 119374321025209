<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Campaign Capped and Shown Report</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.time" class="form-control">
                                <option value="" selected>-- Any Time --</option>
                                <option v-for="time in timeSelector" :key="time" :value="time">{{time}}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.client" class="form-control">
                                <option value="" selected>-- Any Client --</option>
                                <option v-for="client in dropdownClients" :key="client.id" :value="client.name">{{client.name}}</option>
                            </select>
                        </div>
                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="search"
                            style="background-color: #383838"
                        >
                            <i class="fa fa-search" title="Search"></i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mr-1"
                            v-if="showExportButton"
                            @click="exportToExcel"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="reportDetails"
                        :allowExcelExport='true'
                        :allowPaging="true"
                        :allowSorting="true"
                        :allowGrouping='true'
                        :allowFiltering="true"
                        :allowResizing='true'
                        :filterSettings="filterSettings"
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column field='date' minWidth='8' width='100' headerText="Date"></e-column>
                            <e-column field='time_frame' minWidth='8' width='100' headerText="Time"></e-column>
                            <e-column field='client' minWidth='8' width='110' headerText='Client'></e-column>
                            <e-column field='capped' headerText='Capped' minWidth='8' width='100'></e-column>
                            <e-column field='shown' headerText='Shown'    minWidth='8' width='100'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert";
import Vue from "vue";
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Group,
    Filter,
    Resize
} from "@syncfusion/ej2-vue-grids";
import pTemplate from "@/components/pTemplate";
import sha1 from "js-sha1";


Vue.use(GridPlugin);
export default {
    name: '"Service.Report"',

    components:{

    },
    data: function () {
        return {
            filter:{
                to: '',
                from: '',
                provider:"",
                type: "fail",
                time:'',
                client: '',
                product: '',
            },
            dropdownClients: [],
            clients: [],
            products: [],
            report:[],
            reports:[],
            reportDetails:[],

            timeSelector:['12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'],
            pageSettings: {pageSize: 100},
            groupSettings:  {
                showDropArea: false,
                disablePageWiseAggregates: true,
                columns: ['date', 'provider','time_frame']
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            searching: false,
            showExportButton: false,
            reportLoading: false,
            pTemplate: function () {
                return {
                    template : pTemplate
                }
            }
        }
    },
    created: function(){
        this.load();
        this.loadClients();
        this.GetClientsList();
        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter,
            Resize
        ]
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        actionHandler: function(args) {
            console.log(args);
        },
        load: function () {
            //Load the campaign
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            const dateString = [year, month, day].join('-');
            this.filter.from = dateString;
            this.filter.to = dateString;
        },
        loadClients: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params:request}).then(function(resp){
                //Store the stats
                this.clients = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },

        GetClientsList: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey};
            return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        search: function(){
            this.searching = true;
            this.proxyDataset = [];
            this.report = [];
            this.reportLoading = true;

            var request = {'apikey': this.$root.apikey, search:null, filters:{}};
            if(this.filter.from) request.filters.from = this.filter.from;
            if(this.filter.to) request.filters.to = this.filter.to;
            if(this.filter.client) request.filters.client = this.filter.client;

            this.reportDetails =[]; //to empty the array

            axios.get(`${this.$root.serverUrl}/admin/reports/campaign/capped-and-shown`,{params:request}).then((resp) => {

                if(resp.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                }else{
                    this.reportLoading = false;
                    this.showExportButton = true;
                    const result = resp.data.data;
                    if(result.length > 0) {
                        for (var z = 0; z < result.length; z++) {
                            if (result[z].client != null) {
                                if (this.filter.time == "") { // check if the time is any empty string and dont not filter
                                    this.reportDetails.push({
                                        date: this.getDate(result[z].date),
                                        client: result[z].client, capped: result[z].capped,
                                        shown: result[z].shown, time_frame: 'All day'
                                    })
                                } else {
                                    for (var x = 0; x < result[z].details.length; x++) {
                                        this.report.push({
                                            date: this.getDate(result[z].details[x].date),
                                            time_frame: this.getTimeFrame(result[z].details[x].date),
                                            client: result[z].client, capped: result[z].details[x].capped,
                                            shown: result[z].details[x].shown, count: result[z].count
                                        })
                                    }


                                }

                            }

                        }
                        //group data by client and time to filter the data by time frame
                        if(this.filter.time != "") {
                            this.reports = [...this.report.reduce((mp, o) => {
                            const key = JSON.stringify([o.client,o.time_frame]);
                            if (!mp.has(key)) mp.set(key, {...o, capped: 0, shown: 0});
                            mp.get(key).capped += o.capped;
                            mp.get(key).shown += o.shown;
                            return mp;
                        }, new Map).values()];

                        // console.log(this.report)
                        for (var k = 0; k < this.reports.length; k++) {
                            if(this.reports[k].time_frame == this.filter.time)
                            this.reportDetails.push({
                                date: this.reports[k].date,
                                time_frame: this.reports[k].time_frame,
                                client: this.reports[k].client,
                                capped: this.reports[k].capped,
                                shown: this.reports[k].shown
                            })
                        }


                    }
                }else {
                        this.reportLoading = false;
                        this.reportDetails =[];
                    }
                }
                this.searching = false;
            }).catch((err) => {
                this.reportLoading = false;
                this.searching = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            });
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign} ${this.filter.client}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.reportDetails,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        getTimeFrame: function(dateTime){
            const timeFrameMap = [
                '12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'
            ];
            let d = new Date(dateTime);
            return timeFrameMap[d.getHours()];
        },
        hashGeoTracking: function(country,state,city,ip){
            return sha1(`${country}${state}${city},${ip}`);
        },
        hashErrorTracking: function(geoHash,errorCode){
            return sha1(`${geoHash}${errorCode}`);
        }
    }
}
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>

